<template>
  <div class="stock text-left">
    <h1>FAQ</h1>
    <br />
    <h2>Most of the frequently asked questions below.</h2>
    <br />
    <h2>If you can not find your answer please contact me <a href="mailto:ilove@nedvajz.cz">here</a>.</h2>
    <br />
    <br />

    <div class="faq-item" v-for="(question, i) in questions" :key="i">
      <h3><b>{{ question.q }}</b></h3>
      <p v-html="question.a">{{ question.a }}</p>
      <br />
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQ",
  data: function() {
    return {
      questions: [
        {
          q: "Do you check holidays and weekends for trading hours too?",
          a:
            "Yep we check these dates, exchanges closed due holidays are marked by asterisk."
        },
        {
          q: "How many exchanges do you show at the moment?",
          a:
            "We monitor first 20 largest stock exchanges and 21 cryptocurrencty exchanges."
        },
        {
          q: "What to do when I want to trade over these exchanges?",
          a:
            "You can go over our referall links next to each exchange. Note that we get reward from the exchange. On other hand you get some benefits too!"
        },
        {
          q: "What do you think about eToro? Is it safe?",
          a:
            "From our personal experiences and reviews we trust this platform. More in <a href='https://www.investopedia.com/etoro-review-5072808' rel='noopener' target='_blank' title='Go to investopedia eToro review'>investopedia.com review</a>."
        },
        {
          q: "What do you think about XTB? Is it safe?",
          a:
            "From our personal experiences and reviews we trust this platform. More in <a href='https://www.investopedia.com/xtb-review-4587935' rel='noopener' target='_blank' title='Go to investopedia XTB review'>investopedia.com review</a>."
        }
      ]
    };
  }
};
</script>
